export default defineNuxtRouteMiddleware( async to => {
  const { getUser, can, loginDisabled } = useAuth()

  // white list URL
  // aggiungi qui gli url visitabili anche senza essere autenticati
  const whitelistUrl = ['/login', '/reset-pass', '/confirm-reset-pass']
  
  if (whitelistUrl.includes(to.path)) {
    return true
  }

  const user = await getUser()

  if (user?.account_id || loginDisabled.value) {
    // default authenticated path
    if (to.path === '/') {
      return navigateTo('/dashboard')
    }

    if (!can(to?.meta?.action ?? 'view', to?.meta?.entity ?? to.name)) {
      console.error(`Not allowed: ${to?.meta?.action ?? 'view'} - ${to?.meta?.entity ?? to.name}`)
      return navigateTo('/dashboard')
    }
    
    return true
  } else {
    if(!to.path || to.path === '/') {
      return navigateTo('/login')
    } else {
      return navigateTo(`/login?redirect=${to.path}`)
    }
  }
})
